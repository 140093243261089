<template>
  <div class="bg-white box-shadow border-r px-20 pb-20">
    <div class="d-flex-center px-15 border-bottom">
      <h4 class="mr-auto color-333">{{ $t("recharge_manage.title") }}</h4>
      <el-avatar
        size="small"
        :src="coinLogo[$route.params.coin] || info.logo"
      />
      <label class="ml-5" v-text="$route.params.coin"></label>
    </div>

    <!-- 未通过审核或未认证 -->
    <div class="no-authority" v-if="verified!=3">
        <p>KYC {{ $t('account.verify') }}: <el-tag effect="plain" hit :type="verifyState[$store.state.userVerified][0]">{{ verifyState[$store.state.userVerified][1] }}</el-tag></p>
        <p>{{$t('recharge_new.verified_tip')}}</p>
    </div>
    <!-- 未通过审核或未认证 -end -->

    <!-- 充值所有相关 -->
    <div v-if="verified==3">
      <div>
        <div v-if="info.address.length > 0" class="d-flex-center pt-20">
          <el-button
            v-for="(item, index) in info.address"
            :key="index"
            type="danger"
            size="mini"
            :plain="type !== index"
            @click="typeFn(index)"
            >{{ item.tag.toUpperCase() }}</el-button
          >
        </div>
        <div class="border-bottom pb-20 mb-10">
          <div class="p-20 d-flex-center">
            <!-- <el-image
              v-if="info.address[type].address"
              width="200"
              height="200"
              :lazy="lazy"
              :src="'/api/qrcode/wallet/address/' + info.address[type].address"
            /> -->
            <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
          </div>
          <div class="font-14 text-center mb-10">
            {{ info.address.length > 0?info.address[type].address:'' }}
          </div>
          <div class="text-center">
            <el-button
              type="success"
              size="mini"
              icon="el-icon-document-copy"
              plain
              @click="onCopyAddress()"
              >{{ $t("recharge.cc") }}</el-button
            >
          </div>
        </div>
        <div class="p-20 border-bottom">
          <el-form
            ref="ruleForm"
            class="input-form mx-auto"
            :model="formData"
            :rules="rules"
            v-loading="loading"
          >
            <el-form-item prop="amount">
              <el-input
                v-model="formData.amount"
                prefix-icon="el-icon-money"
                :placeholder="$t('amount')"
              />
            </el-form-item>
            <el-form-item prop="voucher">
              <el-upload
                action=""
                accept="image/*"
                :multiple="false"
                :show-file-list="false"
                :before-upload="onUploadVoucher"
                class="w-100 text-center"
              >
                <el-button
                  type="warning"
                  icon="el-icon-upload"
                  plain
                  class="bg-t w-100"
                  >{{ $t("recharge.uv") }}</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item v-if="formData.voucher" class="d-flex-center">
              <el-image
                class="mx-auto voucher-img"
                :src="formData.voucher"
                fit="cover"
                :preview-src-list="srcList"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-s-promotion"
                class="w-100"
                native-type="button"
                @click="submitForm"
                >Submit</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="font-14 color-666 px-20 border-bottom">
          <p v-for="(item, index) in tips[formData.coin]" :key="index">
            {{ item }}
          </p>
        </div>
      </div>
      <!-- <div>
        <h2 class="text-center color-666">
          {{ $route.params.coin }} Forbidden to move in
        </h2>
      </div> -->
      <div v-loading="loadingRecord">
        <div class="d-flex-center px-15 border-bottom">
          <h4 class="mr-auto color-333">{{ $t("recharge.history.title") }}</h4>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-refresh"
            plain
            @click="fatchRecord()"
            >{{ $t("base.refresh") }}</el-button
          >
        </div>

        <div>
          <el-row
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="color-gray px-20 py-15 border-bottom font-14"
          >
            <el-col :span="4">{{ $t('recharge_new.table_item1') }}</el-col>
            <el-col :span="4">{{ $t('recharge_new.table_item2') }}</el-col>
            <el-col :span="4">{{ $t('recharge_new.table_item3') }}</el-col>
            <el-col :span="4">{{ $t('recharge_new.table_item4') }}</el-col>
            <el-col :span="4">{{ $t('recharge_new.table_item5') }}</el-col>
            <el-col :span="6" style="text-align:right;">{{ $t('recharge_new.table_item6') }}</el-col>
          </el-row>
          <el-row
            v-for="(item, index) in list.list"
            :key="index"
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="px-20 py-15 border-bottom font-14"
          >
            <!-- <el-col :span="4">{{ item.id }}</el-col>
            <el-col :span="6">{{ item.amount }}</el-col>
            <el-col :span="8" style="word-break: break-all">{{
              item.hash
            }}</el-col>
            <el-col :span="6">{{ item.create_time }}</el-col> -->

            <el-col :span="4">{{ item.order_no }}</el-col>
            <el-col :span="4">
                <span class="span-style" v-if="Number(item.state)==1" style="color:#e6a23c">
                    {{ $t('recharge_new.table_state1') }}
                </span>
                <span class="span-style" v-if="Number(item.state)==2" style="color:#67c23a">
                    {{ $t('recharge_new.table_state2') }}
                </span>
                <span class="span-style" v-if="Number(item.state)==3" style="color:#f56c6c">
                    {{ $t('recharge_new.table_state3') }}
                </span>
            </el-col>
            <el-col :span="4">{{ item.channel.toUpperCase() }}</el-col>
            <el-col :span="4">{{ item.amount }}</el-col>
            <el-col :span="4">
                <el-image
                style="width: 170px; height: 100px"
                :src="item.image"
                :preview-src-list="[item.image]"
                fit="cover"></el-image>
            </el-col>
            <el-col :span="6" style="text-align:right;">
                {{ item.create_time }}
            </el-col>
          </el-row>
          <div class="d-flex-center pt-20">
            <el-pagination
              :hide-on-single-page="true"
              background
              layout="prev, pager, next"
              :page-size="per_page"
              :current-page="current_page"
              :total="list.total"
              @current-change="onPage"
            />
          </div>
        </div>

      </div>
    </div>
    <!-- <div>
      <div v-if="info.open">
        <div v-if="info.address.length > 0" class="d-flex-center pt-20">
          <el-button
            v-for="(item, index) in info.address"
            :key="index"
            type="danger"
            size="mini"
            :plain="type !== index"
            @click="type = index"
            >{{ item.tag }}</el-button
          >
        </div>
        <div class="border-bottom pb-20 mb-10">
          <div class="p-20 d-flex-center">
            <el-image
              width="200"
              height="200"
              :src="'/api/qrcode/wallet/address/' + info.address[type].address"
            />
          </div>
          <div class="font-14 text-center mb-10">
            {{ info.address[type].address }}
          </div>
          <div class="text-center">
            <el-button
              type="success"
              size="mini"
              icon="el-icon-document-copy"
              plain
              @click="onCopyAddress()"
              >{{ $t("recharge.cc") }}</el-button
            >
          </div>
        </div>
        <div v-if="!info.auto" v-loading="loading" class="p-20 border-bottom">
          <el-form
            ref="ruleForm"
            class="input-form mx-auto"
            :model="formData"
            :rules="rules"
          >
            <el-form-item prop="amount">
              <el-input
                v-model="formData.amount"
                prefix-icon="el-icon-money"
                :placeholder="$t('amount')"
              />
            </el-form-item>
            <el-form-item prop="voucher">
              <el-upload
                action=""
                accept="image/*"
                :multiple="false"
                :show-file-list="false"
                :before-upload="onUploadVoucher"
                class="w-100 text-center"
              >
                <el-button
                  type="warning"
                  icon="el-icon-upload"
                  plain
                  class="bg-t w-100"
                  >{{ $t("recharge.uv") }}</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item v-if="formData.voucher" class="d-flex-center">
              <el-image class="mx-auto voucher-img" :src="formData.voucher" />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-s-promotion"
                class="w-100"
                native-type="button"
                @click="submitForm"
                >Submit</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="font-14 color-666 px-20 border-bottom">
          <p v-for="(item, index) in tips[formData.coin]" :key="index">
            {{ item }}
          </p>
        </div>
      </div>
      <div v-else-if="!loadingData">
        <h2 class="text-center color-666">
          {{ $route.params.coin }} Forbidden to move in
        </h2>
      </div>
      <div v-if="info.open" v-loading="loadingRecord">
        <div class="d-flex-center px-15 border-bottom">
          <h4 class="mr-auto color-333">{{ $t("recharge.history.title") }}</h4>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-refresh"
            plain
            @click="fatchRecord()"
            >{{ $t("base.refresh") }}</el-button
          >
        </div>
        <div v-if="info.auto">
          <el-row
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="color-gray px-20 py-15 border-bottom font-14"
          >
            <el-col :span="4">Order ID</el-col>
            <el-col :span="6">Amount</el-col>
            <el-col :span="8">Hash</el-col>
            <el-col :span="6">Date Time</el-col>
          </el-row>
          <el-row
            v-for="(item, index) in list.data"
            :key="index"
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="px-20 py-15 border-bottom font-14"
          >
            <el-col :span="4">{{ item.id }}</el-col>
            <el-col :span="6">{{ item.amount }}</el-col>
            <el-col :span="8" style="word-break: break-all">{{
              item.hash
            }}</el-col>
            <el-col :span="6">{{ item.create_time }}</el-col>
          </el-row>
          <div class="d-flex-center pt-20">
            <el-pagination
              :hide-on-single-page="true"
              background
              layout="prev, pager, next"
              :page-size="list.per_page"
              :current-page="list.current_page"
              :total="list.total"
              @current-change="onPage"
            />
          </div>
        </div>
        <div v-else>
          <el-row
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="color-gray px-20 py-15 border-bottom font-14"
          >
            <el-col :span="5">Order ID</el-col>
            <el-col :span="5">Amount</el-col>
            <el-col :span="5">Submit time</el-col>
            <el-col :span="5">Done time / Remark</el-col>
            <el-col class="text-right" :span="4">State</el-col>
          </el-row>
          <el-row
            v-for="(item, index) in list.data"
            :key="index"
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="px-20 py-15 border-bottom font-14"
          >
            <el-col :span="5">{{ item.id }}</el-col>
            <el-col :span="5">{{ item.amount }}</el-col>
            <el-col :span="5">{{ item.create_time }}</el-col>
            <el-col :span="5">{{
              item.state == 1 ? item.done_time : item.remark
            }}</el-col>
            <el-col class="text-right" :span="4">
              <el-tag
                :type="state[item.state][0]"
                effect="plain"
                hit
                class="bg-t"
                >{{ state[item.state][1] }}</el-tag
              >
            </el-col>
          </el-row>
          <div class="d-flex-center pt-20">
            <el-pagination
              :hide-on-single-page="true"
              background
              layout="prev, pager, next"
              :page-size="list.per_page"
              :current-page="list.current_page"
              :total="list.total"
              @current-change="onPage"
            />
          </div>
        </div>
      </div>
    </div> -->
    <!-- 充值所有相关 -end -->
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
import { float1 as regexpFloat } from "@/services/regexp";
import XEClipboard from "xe-clipboard";
import QRCode from 'qrcodejs2'
export default {
  data() {
    return {
      coinLogo,
      //   info: {
      //     open: false,
      //     auto: false,
      //     address: [
      //       {
      //         tag: "DEFAULT",
      //         address: "",
      //       },
      //     ],
      //   },
      info: {
        address: [
          {
            tag: "erc20",
            address: "",
          },
          {
            tag: "omni",
            address: "",
          },
          {
            tag: "trc20",
            address: "",
          },
        ],
      },
      type: 0,
      tips: this.$t("recharge.tips"),
      loadingData: true,
      loadingRecord: false,
      loading: false,
      formData: {
        coin: this.$route.params.coin,
        amount: null,
        voucher: null,
        voucher_fileName: null,
      },
      query: { ...this.$route.query },
      list: {
        per_page: 1,
        current_page: 1,
        total: 0,
        data: [],
      },
      state: [
        ["primary", "Checking"],
        ["success", "Success"],
        ["danger", "Fail"],
        ["info", "Cancel"],
      ],
      timer: null,
      rules: {
        amount: [
          {
            required: true,
            pattern: regexpFloat,
            // message: this.$t("wallet.pea"),
            message:  this.$t('recharge_new.amount_mes'),
            trigger: "blur",
          },
        ],
        // voucher: [
        //   {
        //     required: true,
        //     type: "url",
        //     min: 6,
        //     message: "Please upload a voucher file",
        //     trigger: "blur",
        //   },
        // ],
      },

      // 新增
      verified: "",
      verifyState: [
        ["info", this.$t("account.verify_state.unsubmitted")],
        ["danger", this.$t("account.verify_state.fail")],
        ["primary", this.$t("account.verify_state.checking")],
        ["success", this.$t("account.verify_state.success")],
      ],
      lazy: true,
      srcList: [],
      list_img:[],
      current_page:1,
      per_page:5,

    };
  },
  watch: {
    $route: function (to, from) {
      if (to.params.coin === from.params.coin) return;
      // this.fatchData();
      // this.fatchRecord()

      this.getVerify();
    },
  },
  created: function () {

  },
  mounted(){
    // this.fatchData();
    // this.fatchRecord()

    //新增
    this.getVerify();
  },
  methods: {
    //切换通道
    typeFn: function(index){
      this.type = index
      //2023/1/30 改调用前端生成二维码
      this.$nextTick(() => {
        this.creatQrCode(this.info.address[this.type].address)
      })
    },

    // 生成二維碼
    creatQrCode(text) {
      this.$refs.qrCodeUrl.innerHTML = ''
      new QRCode(this.$refs.qrCodeUrl, {
          text: text, // 待生成为二维码的内容
          width: 192,
          height: 192,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
      })
    },

    //获取实名认证信息
    getVerify: async function () {
      const { data } = await this.$request.post("v1/user/info");
      this.$store.commit("userEmail", data.email);
      this.$store.commit("userId", data.id);
      this.$store.commit("userVerified", data.verified);
      this.verified = data.verified;
      this.fatchData();
    },

    //获取钱包地址及其分类
    fatchData: async function () {
      const { data } = await this.$request.post("v1/order/getWalletAddress",{wallet_type:2});
      // this.info.address.forEach(function (item, index) {
      //   item.address = data[item.tag];
      // });
      this.info.address = []
      for(var i in data){
        this.info.address.push({tag:i,address:data[i]})
      }
      this.$nextTick(() => {
        this.creatQrCode(this.info.address[this.type].address)
      })

      this.fatchRecord();

      // let that = this
      // this.$request
      //   .post("v1/order/getWalletAddress")
      //   .then((res) => {
      //     console.log(res.data)
      //     that.info.address.forEach(function (item, index) {
      //       item.address = res.data[item.tag];
      //     });
      //     that.$nextTick(() => {
      //       that.creatQrCode(that.info.address[that.type].address)
      //     })
      //     that.fatchRecord();
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });

      //   const { data } = await this.$request.get(
      //     "recharge/info/coin/" + this.$route.params.coin
      //   );
      //   if (typeof data.address === "string") {
      //     data.address = [
      //       {
      //         tag: "DEFAULT",
      //         address: data.address,
      //       },
      //     ];
      //   }
      //   this.info = data;
      //   this.loadingData = false;
      //   this.fatchRecord();
    },

    //复制地址
    onCopyAddress: function () {
      if (XEClipboard.copy(this.info.address[this.type].address)) {
        this.$message({
          message: this.$t("copied"),
          type: "success",
        });
      }
    },

    //上传图片
    onUploadVoucher: function (file) {
      var that = this;
      if (file.size > 1024 * 1024 * 5) {
        this.$message({
          message: that.$t('imageLimit'),
          type: "error",
        });
        return false;
      }
      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = function () {
      //   // console.log(reader.result); //获取到base64格式图片
      //   that.formData.voucher = reader.result;
      //   that.srcList = [that.formData.voucher];
      // };

      let sendData = new FormData();
      if (file.size > 1024 * 1024 * 2) {
        this.$imgCompress.imgCompress(file)
        .then(f => {
          sendData.append("image", f);
          that.$request
            .post("v1/uploadImage", sendData)
            .then(({ data }) => {
              that.formData.voucher = data.url;
              that.formData.voucher_fileName = data.fileName;
            })
            .catch(() => {});
        })
      }else{
        sendData.append("image", file);
        that.$request
          .post("v1/uploadImage", sendData)
          .then(({ data }) => {
            that.formData.voucher = data.url;
            that.formData.voucher_fileName = data.fileName;
          })
          .catch(() => {});
      }

      // const sendData = new FormData();
      // sendData.append("image", file);
      // that.$request
      //   .post("v1/uploadImage", sendData)
      //   .then(({ data }) => {
      //     that.formData.voucher = data.url;
      //     that.formData.voucher_fileName = data.fileName;
      //   })
      //   .catch(() => {
 
      //   });
      return false;
    },

    submitForm: async function () {
      try {
        await this.$refs.ruleForm.validate();
        if (!this.formData.voucher) {
          return this.$message({
            message: this.$t('recharge_new.message_img'),
            type: "error",
          });
        }
        this.loading = true;

        let that = this
        let $data = await this.$request.post("v1/order/recharge", {
            // money: this.floatingpoint.mul(this.formData.amount,100),
            money: this.formData.amount,
            image:this.formData.voucher_fileName,
            channel:this.info.address[this.type].tag,
            wallet_type:2,
            coin:'USDT',
        });
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.formData.amount = null;
            that.formData.voucher = null;
            that.formData.voucher_fileName = null;
            that.fatchRecord();
            that.loading = false;
          },
        });
      } catch (error) {
        this.loading = false;
      }
      //   try {
      //     await this.$refs.ruleForm.validate();
      //     if(!this.formData.voucher){
      //        return this.$message({
      //             message: '請上传凭证图片',
      //             type: "error",
      //         });
      //     }
      //     this.loading = true;
      //     await this.$request.post("recharge", this.formData);
      //     this.formData.amount = null;
      //     this.formData.voucher = null;
      //     this.fatchRecord();
      //     this.loading = false;
      //   } catch (error) {
      //     this.loading = false;
      //   }
    },

    //充币记录
    fatchRecord: async function () {
      this.loadingRecord = true;
      try {
        const { data } = await this.$request.post(
        'v1/order/getOrderList',
          {
            page:this.current_page,
            pageSize:this.per_page,
            type:1,
            wallet_type:2,
          }
        );
        this.list = data;
        this.loadingRecord = false;
      } catch (error) {
        this.loadingRecord = false;
      }

    //   this.loadingRecord = true;
    //   let url = "recharge/log_pc";
    //   if (this.info.auto) {
    //     url = "recharge/log_auto_pc";
    //   }
    //   try {
    //     const { data } = await this.$request.post(
    //       url + "/coin/" + this.formData.coin,
    //       this.query
    //     );
    //     console.log(data);
    //     this.list = data;
    //     this.loadingRecord = false;
    //   } catch (error) {
    //     this.loadingRecord = false;
    //   }
    },

    onSearch() {
      this.fatchRecord();
      this.$router.replace({
        query: this.query,
      });
    },

    onPage(page) {
        //   this.query.page = page || 1;
        //   this.onSearch();
        this.current_page = page
        this.fatchRecord()
    },
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  width: 400px;
}
.voucher-img {
  width: 360px;
  height: 270px;
}
.no-authority {
  padding: 120px 0;
  box-sizing: border-box;
  text-align: center;
}
</style>